import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Header from '../../../components/blog/header';
import SelectPopover from '../../../components/common/selectPopover';
import CTA from "../../../components/blog/cta";


const BestProductsPage = ({page, pageContext}) => {
  const sortOptions = ['Alphabetical', 'Count Reviews', 'Recent'];
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [searchTerm, setSearchTerm] = useState('');

  const baseUrl = '/tools/best-products/'
  var productFamilies = pageContext.product_families
    .map(p => Object.assign(p, {'count': p.products_products_aggregate.aggregate.count}))
    .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));

  if (searchTerm){
    productFamilies = productFamilies.filter(p => p.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  if (sortOption === 'Alphabetical'){
    productFamilies = productFamilies.sort((a, b) => a.name.localeCompare(b.name));
  } else if (sortOption === 'Recent'){
    productFamilies = productFamilies.sort((a, b) => b.id - a.id);
  } else if (sortOption === 'Count Reviews'){
    productFamilies = productFamilies.sort((a, b) => b.count - a.count);
  }

  return (
    <Layout
      title="Reddit Product Reviews" 
      includeBanner={false} lightHeader={false}
      seoComponent={<SEO title={`Best products discussed on Reddit`} path={baseUrl} description="Explore the most popular products discussed and reviewed on Reddit" generateImage={true} />}
    >
      <div className="content-body markdown-body">
        <Header title="Reddit Product Reviews" subtitle={"Popular products recommended on Reddit."} />
      </div>

      <div className="">
      <div className="flex items-center">
        <h3 className="font-bold opacity-50 mb-2">List of Product Categories</h3>
          <div className="ml-auto flex items-center">

            <div className="ml-4 flex items-center">
              <div className="text-xs opacity-50 hidden sm:block">Sort</div>
              <div className="ml-2 flex items-center">
                <SelectPopover
                  options={sortOptions}
                  currentOption={sortOption}
                  setCurrentOption={(s) => setSortOption(s)}
                  labelField={undefined}
                />
              </div>
            </div>

            <div className="ml-4 flex items-center">
              {/*<div className="text-xs opacity-50 hidden sm:block">Search</div>*/}
              <input
                type="text"
                name="name"
                autoComplete="off"
                className={`ml-2 bg-gray-800 focus:bg-gray-700 border-transparent focus:border-gray-700 placeholder-gray-500 text-white block w-full text-xs rounded-sm p-1 px-2 focus:ring-cyan-500 focus:border-cyan-500`}
                placeholder={`Search name`}
                style={{minWidth: '50px'}}
                value={searchTerm} onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <ul>
          {productFamilies.map(p => (
            <li key={p.slug}>
              <Link to={`${baseUrl}${p.slug}/`} disabled={!p.products_products_aggregate.aggregate.count}
                className={`hover:underline cursor-pointer`}
              >
                <div>{p.name} <span className="font-bold opacity-25 ml-1 text-sm">{p.products_products_aggregate.aggregate.count}</span></div>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-20">
        <CTA />
      </div>


    </Layout>
  )
}


export default BestProductsPage;
