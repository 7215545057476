import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
// import { Helmet } from 'react-helmet'

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CTA from '../../components/blog/cta';
import SubredditSearch from '../../components/tools/subreddit/subredditSearchSelect';
import withLocation from "../../components/common/withLocation"

import Similar from "../../components/tools/subreddit/similar";
import Ranking from "../../components/tools/subreddit/ranking";
import Topics from "../../components/tools/subreddit/topics";
import Flair from "../../components/tools/subreddit/flair";
import Growth from "../../components/tools/subreddit/growth";
import Scoring from "../../components/tools/subreddit/scoring";
import Popular from "../../components/tools/subreddit/popular";
import Themes from "../../components/tools/subreddit/themes";

import { readableNumber } from '../../utils/text';
import { getPopularPosts } from '../../utils/reddit';
import { unslugify, styleKeyword } from '../../utils/text';
import { getParamsFromUrl } from '../../utils/urls';
import { getThemes, getTopics, getFlair } from '../../utils/reddit';

// Page for a vidoe demo
const Subreddit = ({data, pageContext, queryParams}) => {
  const subreddit = data.hasura.subreddit;
  const name = subreddit.name;

  const collection = null; // TODO: add back?
  const rankings = null; // TODO: add back?
  const calculated = subreddit.calculated;
  const related_subreddits = data.hasura.similarSubreddits;
  const top_posts = subreddit.results_submissions_top;
  const new_posts = subreddit.results_submissions_top; // TODO: change to results_submissions_new
  const themes = getThemes(top_posts, subreddit.results_analysis);
  const topics = getTopics(top_posts, subreddit.results_analysis);
  const flair = getFlair(top_posts, subreddit.results_analysis);

  // generate the description
  var description = `r/${name} is a subreddit with ${readableNumber(subreddit.data.count_users)} members`;
  if (subreddit.topics && subreddit.topics >= 2){
    description += ` discussing topics like ${subreddit.topics[0].label} and ${subreddit.topics[1].label}.`;
  } else {
    description += '.'
  }
  if (calculated && calculated.tags){
    description += ` Its distinguishing qualities are that the community is ${calculated.tags.map((t, i) => {
      var descriptor = ''
      if (i === calculated.tags.length - 1 && i > 0){
        descriptor += 'and '
      }
      if (t.includes('established-')){
        descriptor += `is ${t.replace('established-', '')}`
      }
      if (t.includes('size-')){
        descriptor += `${t.replace('size-', '')} in size`
      }
      if (t.includes('activity-')){
        descriptor += `has ${t.replace('activity-', '')} activity`
      }
      if (t.includes('growth-')){
        descriptor += `has ${t.replace('growth-', '')} growth`
      }
      return descriptor;
    }).filter(t => t).join(', ')}.`;
  }

  const title = `r/${name} - Subreddit Stats & Analysis`;
  const seoComponent = <SEO
    type="article" title={title}
    description={description}
    // imageUrl={imageUrl}
    path={`/r/${name}/`}
    generateImage={true}
  />

  var loginUrl = `http://localhost:3000/`;
  if (process.env.GATSBY_APPLICATION_URL){
    loginUrl = `${process.env.GATSBY_APPLICATION_URL}/`
  } else if (process.env.GATSBY_RENDER_FRONTEND_SERVICE){
    loginUrl = `https://${process.env.GATSBY_RENDER_FRONTEND_SERVICE}.onrender.com/`
  }

  return (
    <Layout title={`r/${name}`}
      seoComponent={seoComponent}
      includeBanner={false} lightHeader={false}
    >
      <div className="content-body no-header-margin">

        {/*<div className="mb-6">
          <SubredditSearch />
        </div>*/}

        <div className={`p-2 mb-4 text-white bg-cyan-800 rounded-md text-center`}>
          This is a subreddit preview page. If you have a GummySearch account, please add this Subreddit to your audience to view the full analysis features there.
        </div>

        <div className="md:grid grid-cols-3 gap-x-6">
          
          <div className="col-span-2">
            
            <div className="col-span-2 bg-gray-800 p-6 rounded-md">
              <div className="flex items-center">
                <div className="bg-gray-700 rounded-md h-24 w-24 mr-6 p-2 flex-shrink-0">
                  {subreddit.data.icon ? (
                    <img src={subreddit.data.icon} className="h-full w-full rounded-md" alt={`/r/${subreddit.name}/`} />
                  ) : (
                    <svg className="h-full w-full opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                      <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                    </svg>
                  )}
                </div>
                
                <div>
                  <h1>r/{subreddit.data.name}</h1>
                  <div className="text-gray-400">{readableNumber(subreddit.data.count_users)} members</div>
                </div>

                <div className="pl-4 ml-auto">
                  <a href={subreddit.data.link} target='_blank'
                    className="p-4 rounded-sm text-white block font-bold cursor-pointer opacity-90 hover:opacity-100"
                    style={{backgroundColor: '#FF5700'}}
                  >
                    View on Reddit
                  </a>
                </div>
              </div>
              
                <div>
                  <div className="mt-6 text-base text-gray-400">{description}</div>
                  {subreddit.data.description ? (
                    <div className="mt-4 text-lg">{subreddit.data.description}</div>
                  ) : ''}
                </div>
              
              
              {/*{calculated.tags.length ? (
                <div className="mt-6 flex flex-wrap">
                  {calculated.tags.map(t => (
                    <div key={t} className="p-2 rounded-md bg-gray-700 text-white">{t} subreddits</div>
                  ))}
                </div>
              ) : ''}*/}

            </div>

            <div className="mt-6 flex-wrap space-y-6">
              {/*<Flair subreddit={subreddit.data} />
              <Scoring subreddit={subreddit.data} />*/}

              {calculated ? (
                <Themes subreddit={subreddit.data} themes={themes} />
              ) : ''}

              {calculated ? (
                <Topics subreddit={subreddit.data} topics={topics} flair={flair} />
              ) : ''}

              {top_posts.length ? (
                <Popular subreddit={subreddit.data} posts={top_posts}/>
              ) : ''}
            </div>
          </div>

          <div className="col-span-1 space-y-6">
            
            {collection ? (
              <Ranking subreddit={subreddit.data} collection={collection} rankings={rankings} />
            ) : ''}

            {calculated ? (
              <Growth subreddit={subreddit.data} calculated={calculated} />
            ) : ''}

            {related_subreddits.length ? (
              <Similar subreddit={subreddit.data} related={related_subreddits}/>
            ) : ''}

          </div>
        </div>
      </div>

      {/*<div className="mt-12">
        <CTA />
      </div>*/}

    </Layout>
  )
}

export const pageQuery = graphql`
  query Subreddit($id: Int!, $related: [String!]) {
    hasura {
      subreddit: reddit_subreddit_by_pk(id: $id) {
        id
        name
        data
        calculated
        related_subreddits
        results_analysis
        results_submissions_top
      }  
      similarSubreddits: reddit_subreddit(where: {name: {_in: $related}}) { 
        id
        name
        data
        calculated
      }
    }
  }
`

export default Subreddit;