import React from "react"

import Insight from "./insight";
import HowTo from "./howto";
import Spotlight from "./spotlight";
import Demo from "./demo";
import Podcast from "./podcast";
import Post from "./post";

// general card that serves up specific blog post type
const BlogCard = ({node, mini}) => {

  return (
    <React.Fragment>
      {!node.frontmatter?.type ? (
        <Post key={node.slug} node={node} mini={mini} />
      ) : node.frontmatter.type === "insight" ? (
        <Insight key={node.frontmatter.slug} node={node} mini={mini} />
      ) : node.frontmatter.type === "how-to" ? (
        <HowTo key={node.frontmatter.slug} node={node} mini={mini} />
      ) : node.frontmatter.type === "spotlight" ? (
        <Spotlight key={node.frontmatter.slug} node={node} mini={mini} />
      ) : node.frontmatter.type === "demo" ? (
        <Demo key={node.frontmatter.slug} node={node} mini={mini} />
      ) : node.frontmatter.type === "podcast" ? (
        <Podcast key={node.frontmatter.slug} node={node} mini={mini} />
      ) : ''}
    </React.Fragment>
  );
}

export default BlogCard;
