import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Header from '../../components/blog/header';
import SelectPopover from '../../components/common/selectPopover';
import CTA from "../../components/blog/cta";
import SignupModalTrigger from '../../components/home/signupModal'

import { readableNumber } from '../../utils/text';
import { subredditRankingCategories } from '../../utils/reddit';


const TopSubredditsPage = ({page, pageContext}) => {
  const [showMore, setShowMore] = useState(false);
  const subsToShow = showMore ? 10 : 5;
  const lists = pageContext.lists;
  const tag = pageContext.tag;

  const sizes = [
    {'title': '10k+ Members', 'tag': null},
    {'title': 'Massive (1M+)', 'tag': 'size-massive'},
    {'title': 'Huge (100k - 1M)', 'tag': 'size-huge'},
    {'title': 'Large (10k - 100k)', 'tag': 'size-large'},
    {'title': 'Medium (1k - 10k)', 'tag': 'size-medium'},
    {'title': 'Small (100 - 1k)', 'tag': 'size-small'},
    {'title': 'Tiny (<100)', 'tag': 'size-tiny', 'hide': true},
  ]
  const currentSize = sizes.filter(s => s.tag == pageContext.tag)[0]

  const sections = [
    {'title': 'Growth', 'lists': [
      {'category': 'Daily growth (%)', 'subreddits': lists.growth_daily.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.growth_members_daily)}% Growth`},
      {'category': 'Monthly growth (%)', 'subreddits': lists.growth_monthly.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.growth_members_monthly)}% Growth`},
      {'category': 'Yearly growth (%)', 'subreddits': lists.growth_yearly.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.growth_members_yearly)}% Growth`},
     
      {'category': 'Daily member growth', 'subreddits': lists.per_day_members.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.per_day_members)} Daily Members`},
      {'category': 'Monthly member growth', 'subreddits': lists.per_month_members.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.per_month_members)} Monthly Members`},
      {'category': 'Yearly member growth', 'subreddits': lists.per_year_members.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.per_year_members)} Yearly Members`},

    ]},
    {'title': 'Engagement', 'lists': [
      {'category': 'Posts per day', 'subreddits': lists.velocity.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.velocity)} Posts`},
      {'category': 'Comments per post', 'subreddits': lists.average_comments.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.average_comments)} Comments`},
    ]},
    {'title': 'Scoring', 'lists': [
       {'category': 'Upvotes per post', 'subreddits': lists.average_upvotes.slice(0, subsToShow), 'subtitle': (s) => `${parseInt(s.calculated.average_comments)} Upvotes`},
       {'category': 'Max upvotes', 'subreddits': lists.max_upvotes.slice(0, subsToShow), 'subtitle': (s) => `${s.calculated.max_upvotes} Upvotes`},
       // {'title': 'Max Comments', 'subreddits': lists.max_comments.slice(0, subsToShow), 'subtitle': (s) => `${s.calculated.max_comments} Comments`},
    ]},
    {'title': 'Size', 'lists': [
      {'category': 'Largest', 'subreddits': lists.largest.slice(0, subsToShow), 'subtitle': (s) => `${readableNumber(s.data.count_users)}`}
    ]},
    {'title': 'Age', 'lists': [
      {'category': 'Oldest', 'subreddits': lists.oldest.slice(0, subsToShow), 'subtitle': (s) => `${s.calculated.total_days} Days`},
    ]},
  ];

  const getMetricValue = (section, subreddit) => {
    // display this card's metric value
    const category = subredditRankingCategories.filter(s => s.label === section.category)[0];
    if (!category || !category.display) return '';
    try {
      return category.display(subreddit)
    } catch (error) {
      return '...'
    }
  }

  const currentPath = `/tools/top-subreddits/${tag ? `${tag}/` : ''}`

  return (
    <Layout
      title="Top Subreddit Lists" ///subtitle={currentSize ? currentSize.title : ''}
      includeBanner={false} lightHeader={false}
      seoComponent={<SEO title={`Top Subreddit Lists ${tag ? `(${currentSize.title})` : ''}`} path={currentPath} description="Explore largest, fastest growing, and more engaged subreddits" generateImage={true} />}
    >
      <div className="content-body markdown-body">
        <Header title="Top Subreddit Lists" subtitle={currentSize ? currentSize.title : ''} />
      </div>

      <div className="flex mb-4">
        <div className="flex flex-wrap mx-auto">
          {sizes.filter(s => !s.hide).map(s => (
            <Link to={`/tools/top-subreddits/${s.tag ? `${s.tag}/` : ''}`} key={s.title}
              className={`px-3 py-2 text-white rounded-md mr-2 mb-2 cursor-pointer opacity-90 hover:opacity-100 ${s === currentSize ? 'bg-cyan-500' : 'bg-gray-800'}`}
            >
              {s.title}
            </Link>
          ))}
        </div>
      </div>

      <div className="flex">
        {/*<div className="w-36 mr-2 pt-14">
          {sections.map(s => (
            <a href={`#${s.title}`} key={s.title} className="font-bold text-lg px-2 py-1 text-gray-500 cursor-pointer hover:text-gray-200 flex items-center rounded-md hover:bg-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
              </svg>
              <div>{s.title}</div>
            </a>
          ))}
        </div>*/}
        <div className="space-y-6 flex-grow">
          {sections.map(s => (
            <div key={s.title} id={s.title}>
              <h2 className="font-bold mb-2">{s.title}</h2>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
                {s.lists.map(r => (
                  <div key={r.category} className="bg-gray-800 p-4 rounded-md">
                    <div className="pb-2 mb-2 border-b border-gray-700">
                      <h2 className="mt-0 font-bold text-xl">
                        {r.category}{' '}{/*<span className="opacity-50 text-base">{r.subtitle}</span>*/}
                      </h2>
                    </div>
                    <div className="mt-0 -mx-2">
                      {r.subreddits.map(subreddit => (
                        <div //to={`/r/${subreddit.name}/`}
                          key={subreddit.name}
                          className="p-2 rounded-md block flex group"
                        >
                          <Link to={`/r/${subreddit.name}/`} className="bg-gray-700 rounded-md h-12 w-12 mr-2 p-2 flex-shrink-0">
                            {false && subreddit.data.icon ? (
                              <img src={subreddit.data.icon} className="h-full w-full rounded-md" alt={`/r/${subreddit.name}/`} />
                            ) : (
                              <svg className="h-full w-full opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                              </svg>
                            )}
                          </Link>
                          
                          <div className="w-full">
                            <h3 className="text-lg font-bold">
                              <Link to={`/r/${subreddit.name}/`} className="font-semibold border-b-2 border-dotted border-cyan-800 hover:border-cyan-500">
                                r/{subreddit.name}
                              </Link>
                            </h3>
                            <div className="text-gray-400 flex items-center text-sm w-full">
                              <div className="font-semibold text-sm mr-auto">{readableNumber(subreddit.calculated.current_members)} members</div>   

                              <div className="flex-grow text-right ml-auto text-green-500 font-semibold">{getMetricValue(r, subreddit)}</div>                                           
                            </div>
                          </div>

                          {/*<div className="ml-auto text-green-400 flex items-center text-sm">
                            <div className="font-semibold text-sm">{getMetricValue(r, subreddit)}</div>                              
                          </div>*/}
                        </div>
                      ))}
                    </div>

                    {!showMore ? (
                      <div className="opacity-50 hover:opacity-75 pt-4 text-center cursor-pointer text-sm" onClick={() => setShowMore(true)}>Show More</div>
                    ) : subsToShow >= 10 ? (
                      <SignupModalTrigger initiator="tools-top-subreddits-more">
                        <div className="opacity-50 hover:opacity-75 pt-4 text-center cursor-pointer text-sm">
                          Show Full List
                        </div>
                      </SignupModalTrigger>
                    ) : ''}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-12">
        <CTA />
      </div>

    </Layout>
  )
}

// export const pageQuery = graphql`
//   query Subreddit($id: Int!, $related: [String!]) {
//     hasura {
//       largest: reddit_subreddit(where: {name: {_in: $related}}) { 
//         id
//         name
//         data
//         calculated
//       }
//     }
//   }
// `


export default TopSubredditsPage;
