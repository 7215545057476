import React from "react"
import { Link } from "gatsby"

import Image from "../image"

// wordpress post
const Post = ({node, mini}) => {

  return (
    <div key={node.slug} className="flex group flex-col rounded-lg shadow-lg overflow-hidden">
      {!mini && node.featuredImage?.node?.link ? (
        <Link to={`/post/${node.slug}/`} className="flex-shrink-0">
          <img className="h-48 w-full object-cover filter grayscale-50 group-hover:grayscale-25" src={node.featuredImage.node.link} alt={node.title} />
        </Link>
      ) : ''}
      <div className="flex-1 bg-gray-800 text-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          {!mini ? (
            <div className="text-sm font-medium text-cyan-300 opacity-50 mb-2">
              <div>
                Post
              </div>
            </div>
          ) : ''}
          <Link to={`/post/${node.slug}/`} className="block">
            <p className="text-xl font-semibold text-white">{node.title}</p>
            <p className="mt-3 text-base text-gray-300 line-clamp-3">{node.excerpt && node.excerpt.replace(/<[^>]+>/g, '')}</p>
          </Link>
        </div>
        {true || !mini ? (
          <div className="mt-4 flex items-center">
            {!mini ? (
              <div className="flex-shrink-0 mr-3">
                <div>
                  <span className="sr-only">author</span>
                  <Image className="h-10 w-10 rounded-full object-cover" fileName={'avatar-fed.png'} alt={"Fed from GummySearch"} />
                </div>
              </div>
            ) : ''}
            <div className="">
              {!mini ? (
                <div className="text-sm font-medium text-gray-900">
                  <div className="text-white">
                    author
                  </div>
                </div>
              ) : ''}
              <div className="flex space-x-1 text-sm text-gray-300">
                <time dateTime={node.dateGmt}>{node.dateGmt.split('T')[0]}</time>
              </div>
            </div>
          </div>
        ) : ''}
      </div>
    </div>
  );
}

export default Post;
