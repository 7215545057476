import React from "react"
import { graphql, Link } from "gatsby"
// import { Helmet } from 'react-helmet'

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../components/layout';
import SEO from '../components/seo';
import CTA from '../components/blog/cta';
import Recommended from '../components/blog/recommended';
import Header from '../components/blog/header';

import "../styles/content.css"


// Blog post from wordpress
export default function Post({data}) {
  const { allWpPost } = data;
  const post = allWpPost.nodes[0]

  if (!post || !post.title){
    return <div>No post found</div>
  }

  const author = post.author.node


  const seoComponent = <SEO title={post.title}
    description={post.excerpt ? post.excerpt.replace(/<[^>]+>/g, '') : ''}
    path={`/post/${post.slug}/`}
    imageUrl={post.featuredImage?.node?.link}
    generateImage={post.featuredImage ? false : true}
  />


  return (
    <Layout title={post.title}
      subsubtitle={''}
      seoComponent={seoComponent}
      includeBanner={false} lightHeader={false}
    >
      <div className="content-body markdown-body max-w-5xl mx-auto">
        <Header title={post.title} subtitle={post.dateGmt ? post.dateGmt.split('T')[0] : ''} />

        {post.featuredImage?.node?.mediaItemUrl ? (
          <div className="text-center mb-4">
            <img src={post.featuredImage?.node?.mediaItemUrl} alt={post.title} className="w-full rounded-lg max-w-xl mx-auto" />
          </div>
        ) : ''}

        <div
          className="mt-4 wordpress-content"
          dangerouslySetInnerHTML={{__html: post.content}}
        />

        {/* <Recommended nodes={recommended.edges}/> */}
      </div>

      <div className="mt-10">
        <CTA type="post"/>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    allWpPost(
      limit: 1,
      filter: {slug: {eq: $slug}}
    ) {
      nodes {
        id
        date
        slug
        title
        status
        dateGmt
        tags {
          nodes {
            name
            slug
            count
          }
        }
        author {
          node {
            name
            firstName
            slug
            description
            avatar{
              url
            }
          }
        }
        featuredImage {
          node {
            link
            mediaItemUrl
          }
        }
        modified
        content
        excerpt
      }
    }
  }
`
