import React, { useState } from "react"
import { Link, graphql} from "gatsby"
import { Transition, Popover } from '@headlessui/react'

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Header from '../../../components/blog/header';
import CTA from "../../../components/blog/cta";

import MakeReviews from '../../../components/tools/products/makeReviews';
import ModelReviews from '../../../components/tools/products/modelReviews';


const ProductFamilyPage = ({data, pageContext}) => {
  const displayTypes = ['Brand', 'Product']
  const [displayType, setDisplayType] = useState(displayTypes[0]);
  const productFamily = data.hasura.products_productfamily_by_pk;

  // optional filters
  const [subredditFilter, setSubredditFilter] = useState([]); // list of names
  const [postFilter, setPostFilter] = useState([]); // list of ids
  
  const products = productFamily.products_products.map(p => {
    // do some calculations for each product 
    const totalStars = p.products_productreviews.map(r => r.one_to_five_rating).reduce((a, b) => a + b, 0)
    return Object.assign(p, {
      'stars_sum': totalStars,
      'stars_average': (totalStars / p.products_productreviews.length).toFixed(1),
      'reviews': p.products_productreviews, // just to keep it consistent with the other data
    })
  }).sort((a,b) => (a.stars_sum < b.stars_sum) ? 1 : ((b.stars_sum < a.stars_sum) ? -1 : 0));;

  // organize reviews by model (easy, just remove those that don't have a model)
  const productModels = products.filter(p => p.model);

  // organize reviews by make
  var productMakes = [];
  products.forEach(p => {
    var makeIndex = productMakes.map(m => m.make).indexOf(p.make);
    if (makeIndex === -1){
      productMakes.push({'make': p.make, 'products': [], 'reviews': []});
      makeIndex = productMakes.length - 1;
    }
    productMakes[makeIndex].reviews = [...productMakes[makeIndex].reviews, ...p.products_productreviews]
      .sort((a,b) => (a.reddit_comment_data.timestamp_utc < b.reddit_comment_data.timestamp_utc) ? 1 : ((b.reddit_comment_data.timestamp_utc < a.reddit_comment_data.timestamp_utc) ? -1 : 0))
      .sort((a,b) => (a.one_to_five_rating < b.one_to_five_rating) ? 1 : ((b.one_to_five_rating < a.one_to_five_rating) ? -1 : 0))
      
    if (p.model){
      productMakes[makeIndex].products.push(p)
    }
  });
  
  // do some calculations for each product make
  productMakes = productMakes.map(m => {
    const totalStars = m.reviews.map(r => r.one_to_five_rating).reduce((a, b) => a + b, 0)
    return Object.assign(m, {
      'stars_sum': totalStars,
      'stars_average': (totalStars / m.reviews.length).toFixed(1),
    })
  }).sort((a,b) => (a.stars_sum < b.stars_sum) ? 1 : ((b.stars_sum < a.stars_sum) ? -1 : 0));

  // total subreddits & posts & reviews (for subtitle & filters)
  var subreddits = {}; // append reviews here for now, will eventually convert to ordered list
  var reviews = [];
  var posts = {}; // append reviews here for now, will eventually convert to ordered list
  products.forEach(p => {
    p.products_productreviews.forEach(r => {
      // total reviews
      reviews.push(Object.assign(r, {
        'product': p
      }));

      // do by subreddit
      if (r.reddit_comment_data.subreddit_name){
        subreddits[r.reddit_comment_data.subreddit_name] = [...(subreddits[r.reddit_comment_data.subreddit_name] || []), r];
      }

      if (r.reddit_submission_data.id){
        posts[r.reddit_submission_data.id] = [...(posts[r.reddit_submission_data.id] || []), r]
      }
    })
  });
  subreddits = Object.keys(subreddits).map(k => Object.assign({}, {
    'name': k,
    'count': subreddits[k].length,
    'reviews': subreddits[k],
  })).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));
  posts = Object.keys(posts).map(k => Object.assign({}, {
    'id': k,
    'submission': posts[k][0].reddit_submission_data,
    'count': posts[k].length,
    'reviews': posts[k],
  })).filter(p => {
    if (!subredditFilter.length) return true;
    if (subredditFilter.indexOf(p.submission.subreddit_name) > -1) return true;
  }).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));

  const title = `Best ${productFamily.name} on Reddit`;
  const countSubs = 3;
  var subtitle = `${reviews.length} reviews from ${subreddits.filter((s, i) => i < countSubs).map(s => s.name).join(', ')}`;
  if (subreddits.length > countSubs){subtitle += ` and ${subreddits.length - countSubs} more subreddit${subreddits.length - countSubs > 1 ? 's' : ''}`};

  // get the filtered down reviews
  const filteredReviews = reviews.filter(r => {
    if (subredditFilter.length > 0 && subredditFilter.indexOf(r.reddit_comment_data.subreddit_name) === -1){return false}
    if (postFilter.length > 0 && postFilter.indexOf(r.reddit_submission_data.id) === -1){return false}
    return true;
  });

  // recalculate the ratings for product makes & models after the reviews were filtered
  var filteredProductMakes = productMakes;
  var filteredProductModels = productModels;
  if (filteredReviews.length !== reviews.length){
    filteredProductMakes = productMakes.map(p => {
      return Object.assign(p, {
        'reviews': p.reviews.filter(r => filteredReviews.map(fr => fr.id).indexOf(r.id) > -1),
      });
    }).filter(m => m.reviews.length > 0).map(m => {
      const totalStars = m.reviews.map(r => r.one_to_five_rating).reduce((a, b) => a + b, 0)
      return Object.assign(m, {
        'stars_sum': totalStars, 'stars_average': (totalStars / m.reviews.length).toFixed(1),
      })
    }).sort((a,b) => (a.stars_sum < b.stars_sum) ? 1 : ((b.stars_sum < a.stars_sum) ? -1 : 0));
    
    // recalculate the ratings for product makes after the reviews were filtered
    filteredProductModels = productModels.map(p => {
      return Object.assign(p, {
        'reviews': p.reviews.filter(r => filteredReviews.map(fr => fr.id).indexOf(r.id) > -1),
      });
    }).filter(m => m.reviews.length > 0).map(m => {
      const totalStars = m.reviews.map(r => r.one_to_five_rating).reduce((a, b) => a + b, 0)
      return Object.assign(m, {
        'stars_sum': totalStars, 'stars_average': (totalStars / m.reviews.length).toFixed(1),
      })
    }).sort((a,b) => (a.stars_sum < b.stars_sum) ? 1 : ((b.stars_sum < a.stars_sum) ? -1 : 0));
  }

  return (
    <Layout
      title={title} subtitle={subtitle}
      includeBanner={false} lightHeader={false}
      seoComponent={<SEO title={`Best ${productFamily.name} discussed on Reddit`}
        path={`/tools/best-products/${productFamily.slug}/`}
        description={subtitle}
        generateImage={true}
    />}
    >
      <div className="content-body markdown-body">
        <Header
          title={title}
          subtitle={subtitle}
        />
      </div>

      {/* Filter bar */}
      <div className="flex mb-8 text-gray-400">
        <div className="flex items-center space-x-1">
          <div>{filteredReviews.length} reviews from</div>

          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button className={`text-gray-200 hover:underline font-bold cursor-pointer flex items-center`}>
                  {subredditFilter.length && subredditFilter.length !== subreddits.length ? `${subredditFilter.length}/` : ''}
                  {subreddits.length} subreddits
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>
                </Popover.Button>
                <Transition
                  show={open}
                  as={React.Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel static
                    className="absolute z-10 top-full left-0 mt-2 px-1 w-screen max-w-xs sm:px-0 text-sm"
                    style={{maxWidth: '300px'}}
                  >
                    <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-4 bg-white text-gray-900 p-4  max-h-96 overflow-auto">
                        {subreddits.map(s => (
                          <div key={s.name} className="cursor-pointer -m-2 p-2 flex items-center rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            onClick={() => {
                              if (subredditFilter.includes(s.name)){
                                setSubredditFilter(subredditFilter.filter(f => f !== s.name));
                              } else {
                                setSubredditFilter([...subredditFilter, s.name]);
                              }
                              setPostFilter([]); // always clear this
                            }}
                          >
                            {subredditFilter.includes(s.name) || subredditFilter.length === 0 ? (
                              <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${subredditFilter.includes(s.name) ? 'text-green-400' : 'text-gray-300'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                              </svg>
                            )}
                            <p className="font-medium text-gray-700 ml-2 mr-2">{s.name} </p>
                            <div className="font-bold ml-auto opacity-25">{s.count}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <div>and</div>
          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button className={`text-gray-200 hover:underline font-bold cursor-pointer flex items-center`}>
                  {postFilter.length && postFilter.length !== posts.length ? `${postFilter.length}/` : ''}
                  {posts.length} posts
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>
                </Popover.Button>
                <Transition
                  show={open}
                  as={React.Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel static
                    className="absolute z-10 top-full left-0 mt-2 px-1 w-screen max-w-xs sm:px-0 text-sm"
                    style={{maxWidth: '400px'}}
                  >
                    <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-4 bg-white text-gray-900 p-4 max-h-96 overflow-auto">
                        {posts.map(p => (
                          <div key={p.id} className="cursor-pointer -m-2 p-2 flex items-center rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            onClick={() => {
                              if (postFilter.includes(p.id)){
                                setPostFilter(postFilter.filter(f => f !== p.id));
                              } else {
                                setPostFilter([...postFilter, p.id]);
                              }
                            }}
                          >
                            {postFilter.includes(p.id) || postFilter.length === 0 ? (
                              <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 flex-shrink-0 ${postFilter.includes(p.id) ? 'text-green-400' : 'text-gray-300'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300 flex-shrink-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                              </svg>
                            )}
                            <div className="ml-2 mr-2">
                              <p className="font-medium text-gray-700">{p.submission.title} </p>
                              <div className="text-xs opacity-50">In {p.submission.subreddit_name} on {p.submission.timestamp_readable}</div>
                            </div>
                            <div className="font-bold ml-auto opacity-25 flex-shrink-0">{p.count}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
        <div className="ml-auto">
          <div className="flex items-center space-x-2">
            {displayTypes.map((d, i) => (
              <React.Fragment key={d}>
                <div key={d} className={`font-semibold ${d === displayType ? 'text-gray-200' : 'hover:text-gray-200 cursor-pointer'}`}
                  onClick={() => setDisplayType(d)}
                >
                  By {d}
                </div>
                {i !== displayTypes.length - 1 ? (
                  <span className="opacity-50 mx-2">/</span>
                ) : ''}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      {/* content */}
      <div id="reviews-content">
        {displayType === 'Brand' ? (
          <MakeReviews makes={filteredProductMakes}
            clickedOnModel={(product) => {
              // switch to model view and open it up
              setDisplayType('Product');
              setTimeout(() => {
                const domId = `model-${product.id}`;
                const domElement = document.getElementById(domId);
                if (domElement){
                  domElement.scrollIntoView({behavior: 'smooth'});
                  domElement.classList.add('bg-green-900');
                };
              }, 100);
            }}
          />
        ) : displayType === 'Product' ? (
          <ModelReviews models={filteredProductModels} />
        ) : ''}
      </div>

      <div className="mt-20">
        <CTA />
      </div>
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductFamilyPage($id: Int!) {
    hasura {
      products_productfamily_by_pk(id: $id) {
        id
        name
        slug
        show_in_results
        products_products(
          limit: 100
          order_by: {products_productreviews_aggregate: {count: desc}}
        ) {
          id
          make
          model
          products_productreviews {
            id
            one_to_five_rating
            highlight
            reddit_comment_data
            reddit_submission_data
          }
        }
      }
    }
  }
`


export default ProductFamilyPage;
